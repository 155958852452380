import Group from '../images/Group.png';
import Model from '../images/Model.png';
import Frame from '../images/Frame.png';
import AiMobile from '../images/aiMobile.png';

const AiMeasurement = () => {
	return (
		<div className='lg:flex justify-between lg:w-[1112px] lg:h-[650px] bg-[#EA702C0A] rounded-[25px] lg:mt-[64px] mb-[24px]'>
			<div className='flex flex-col justify-between lg:mt-[77px] lg:ml-[72px] lg:w-[305px]'>
				<div className='lg:pt-0 pt-[49px] pl-[31px] pr-[22px] lg:pr-0 lg:pl-0'>
					<p
						style={{ fontFamily: 'EinaBold' }}
						className='lg:text-[36px] text-[26px] font-bold'
					>
						AI Measurement Technology
					</p>
					<p
						style={{ fontFamily: 'EinaO1Regular' }}
						className='lg:text-[16px] text-[14px] font-normal lg:pt-0 pt-[16px]'
					>
						Our AI system uses two photos to provide accurate measurements for a
						perfect fit.
					</p>
				</div>

				<img src={Frame} className='lg:pb-[99px] hidden lg:block' />
			</div>
			<div className='lg:hidden block mb-[48px]'>
				<img src={AiMobile} />
			</div>
			<div className='hidden lg:block relative lg:w-[700px]'>
				<div className='absolute top-[43px] right-[100px]'>
					<img src={Model} className='lg:w-[287px] lg:h-[565px]' />
				</div>
				<div className='absolute top-[202px] left-[30px]'>
					<img src={Group} className='lg:w-[390px] lg:h-[453px]' />
				</div>
			</div>
		</div>
	);
};

export default AiMeasurement;
