// src/components/Navbar.js

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink from react-router-dom
import TopLogo from '../images/top-logo.png';
import OpenIcon from '../images/mobileNav.png';
import CloseIcon from '../images/closeIcon.png';

const TopNavbar = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	return (
		<div className='bg-white'>
			{/* Desktop nav  */}
			<nav className='lg:block hidden bg-white text-[#301F1EB2] mt-[33px] mx-[156px] lg:h-[101px]'>
				<div className='container mx-auto flex justify-between items-center'>
					<a href='/' className='text-2xl font-bold lg:w-[113px]'>
						<img src={TopLogo} />
					</a>
					{/* Navigation links */}
					<ul className={`lg:flex space-x-8 lg:block`}>
						<li>
							<NavLink
								to='/'
								className={({ isActive }) =>
									isActive
										? 'text-orange-400 font-bold'
										: 'hover:text-orange-400'
								}
							>
								Home
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/features'
								className={({ isActive }) =>
									isActive
										? 'text-orange-400 font-bold'
										: 'hover:text-orange-400'
								}
							>
								Features
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/sellmia'
								className={({ isActive }) =>
									isActive
										? 'text-orange-400 font-bold'
										: 'hover:text-orange-400'
								}
							>
								SellMIA
							</NavLink>
						</li>
						<li>
							<NavLink
								to='/about'
								className={({ isActive }) =>
									isActive
										? 'text-orange-400 font-bold'
										: 'hover:text-orange-400'
								}
							>
								About Us
							</NavLink>
						</li>

						<li>
							<NavLink
								to='/contact'
								className={({ isActive }) =>
									isActive
										? 'text-orange-400 font-bold'
										: 'hover:text-orange-400'
								}
							>
								Contact
							</NavLink>
						</li>
					</ul>
					<div
						className={`lg:flex space-x-8 ${
							isMenuOpen ? 'block' : 'hidden'
						} lg:block`}
					>
						<div className='w-[126px] h-[48px] border rounded-[78px] border-[#EA702C] text-[#EA702C] underline flex justify-center items-center '>
							Inquiry
						</div>
					</div>
				</div>
			</nav>
			{/* Mobile nav */}
			<div className='relative lg:hidden w-full'>
				<div className='lg:hidden flex justify-between'>
					{/* Hamburger menu for small screens */}
					<img src={TopLogo} className='w-[82px] h-[24px] ml-[20px]' />
					<div className='w-[34px] h-[34px]' onClick={toggleMenu}>
						<button className='text-white focus:outline-none'>
							{!isMenuOpen ? <img src={OpenIcon} /> : <img src={CloseIcon} />}
						</button>
					</div>
				</div>
				<div
					className={`absolute text-[36px] ${
						isMenuOpen ? 'block' : 'hidden'
					}  px-[16px] bg-white w-full h-[350px] pt-[40px]`}
				>
					<ul className='flex flex-col'>
						<li>
							<NavLink
								onClick={toggleMenu}
								to='/'
								className={({ isActive }) =>
									isActive
										? 'text-orange-400 font-bold'
										: 'hover:text-orange-400'
								}
							>
								Home
							</NavLink>
						</li>
						<li>
							<NavLink
								onClick={toggleMenu}
								to='/features'
								className={({ isActive }) =>
									isActive
										? 'text-orange-400 font-bold'
										: 'hover:text-orange-400'
								}
							>
								Features
							</NavLink>
						</li>
						<li>
							<NavLink
								onClick={toggleMenu}
								to='/sellmia'
								className={({ isActive }) =>
									isActive
										? 'text-orange-400 font-bold'
										: 'hover:text-orange-400'
								}
							>
								Sell MIA
							</NavLink>
						</li>
						<li>
							<NavLink
								onClick={toggleMenu}
								to='/about'
								className={({ isActive }) =>
									isActive
										? 'text-orange-400 font-bold'
										: 'hover:text-orange-400'
								}
							>
								About us
							</NavLink>
						</li>
						<li>
							<NavLink
								onClick={toggleMenu}
								to='/contact'
								className={({ isActive }) =>
									isActive
										? 'text-orange-400 font-bold'
										: 'hover:text-orange-400'
								}
							>
								Contact
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default TopNavbar;
