import React, { useState } from 'react';
//import { collection, addDoc } from 'firebase/firestore';
//import { db } from './firebase';

const Form = () => {
	const [formData, setFormData] = useState({
		name: '',
		email: '',
		message: '',
	});

	/**const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
**/
	/** const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add form data to Firestore
      await addDoc(collection(db, "formData"), formData);
      alert("Form submitted successfully!");
      setFormData({ name: "", email: "", message: "" }); // Reset form
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Error submitting form");
    }
  }; **/

	return (
		<form onSubmit=''>
			<div className='lg:flex lg:gap-[20px] block'>
				<div className='lg:flex lg:flex-col lg:mb-[39px] mb-[15px]'>
					<h1 className='mb-[15px]'>What’s your name</h1>
					<input
						className='border w-full lg:w-[232px] h-[41px] rounded-[8px] px-[12px] text-xs'
						type='text'
						name='name'
						placeholder='Enter your name here'
						required
					/>
				</div>
				<div className='lg:flex lg:flex-col mb-[15px]'>
					<h1 className='mb-[15px]'>What’s your surname</h1>
					<input
						className='border  w-full lg:w-[232px] h-[41px] rounded-[8px] px-[12px] text-xs'
						type='text'
						name='surename'
						placeholder='Enter your surname here'
						required
					/>
				</div>
			</div>
			<div className='lg:flex flex-col mb-[39px]'>
				<h1 className='mb-[15px]'>What’s your email address</h1>
				<input
					className='border w-full lg:w-[232px] w-full h-[41px] rounded-[8px] px-[12px] text-xs'
					type='email'
					name='email'
					placeholder='Enter your email address here'
					required
				/>
			</div>
			<div className='lg:flex lg:flex-col block'>
				<h1 className='mb-[15px]'>Your message</h1>
				<textarea
					className='rounded-[8px] border w-full lg:w-[483px] p-[26px] text-xs'
					name='message'
					placeholder='Write your message here'
					rows={10}
					required
				/>
			</div>
			<button
				className='w-[159px] h-[48px] rounded-[78px] border border-[#EA702C] text-[13px] text-[#EA702C] mt-[40px]'
				type='button'
			>
				Send Message
			</button>
		</form>
	);
};

export default Form;
