// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom'; // Import Route and Routes
import About from './pages/About'; // About page component
import Features from './pages/Features'; // Services page component
import Contact from './pages/Contact'; // Contact page component
import Home from './pages/Home';
import SellMIA from './pages/Sellmai';
import TopNavbar from './components/topnav';
import Footer from './components/footer';

const App = () => {
	return (
		<div className='h-screen lg:w-full flex flex-col justify-between'>
			<div className='mt-[64px] mr-[16px] lg:m-0'>
				<TopNavbar />
			</div>

			<div className='flex-1 lg:mx-[156px] m-[16px]'>
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/about' element={<About />} />
					<Route path='/features' element={<Features />} />
					<Route path='/sellmia' element={<SellMIA />} />
					<Route path='/contact' element={<Contact />} />
				</Routes>
			</div>

			<Footer />
		</div>
	);
};

export default App;
