import React, { useState, useEffect } from 'react';
import AiMeasurement from './AiMeasurement';
import Customization from './Customization';
import Influencer from './Influencer';
import MarketOpportunity from './MarketOpportunity';

const Carousel = () => {
	const slides = [
		{
			id: 1,
			content: <AiMeasurement />,
		},
		{
			id: 2,
			content: <Customization />,
		},
		{
			id: 3,
			content: <Influencer />,
		},
		{
			id: 4,
			content: <MarketOpportunity />,
		},
	];
	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) =>
				prevIndex === slides.length - 1 ? 0 : prevIndex + 1
			);
		}, 60000); // Change slide every 5 seconds

		return () => clearInterval(interval); // Cleanup on unmount
	}, [slides.length]);
	const [currentIndex, setCurrentIndex] = useState(0);

	const goToPrevious = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex === 0 ? slides.length - 1 : prevIndex - 1
		);
	};

	const goToNext = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex === slides.length - 1 ? 0 : prevIndex + 1
		);
	};

	return (
		<div className='relative w-full  mx-auto mb-8 mt-[49px] lg-mt-0'>
			{/* Slide */}
			<div className='overflow-hidden rounded-lg'>
				{slides[currentIndex].content}
			</div>

			{/* Controls */}
			<button
				onClick={goToPrevious}
				className='absolute lg:top-1/2 left-0 w-[46px] h-[46px] transform -translate-y-1/2 bg-[#967C6D] text-white p-2 rounded-full hover:bg-gray-700'
			>
				&#8592;
			</button>
			<button
				onClick={goToNext}
				className='absolute lg:top-1/2 right-0 w-[46px] h-[46px] transform -translate-y-1/2 bg-[#EA702C] text-white p-2 rounded-full hover:bg-gray-700'
			>
				&#8594;
			</button>

			{/* Indicators */}
			<div className='flex justify-center lg:mt-4 space-x-2 items-center justify-content'>
				{slides.map((slide, index) => (
					<button
						key={slide.id}
						onClick={() => setCurrentIndex(index)}
						className={`w-3 h-3 flex items-center  rounded-full ${
							currentIndex === index
								? 'bg-[#EA702C] w-[26.43px] h-[6.43px]'
								: 'bg-[#E5DEDA] w-[9.29px] h-[6.43px]'
						}`}
					></button>
				))}
			</div>
		</div>
	);
};

export default Carousel;
