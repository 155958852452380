import React, { useState } from 'react';
import ContactUs from '../images/contact.png';
import Form from '../components/ContactForm';

const Contact = () => {
	return (
		<div className='mb-[80px]'>
			<div className='lg:h-[113px] lg:w-[712px] mb-[36px]'>
				<h1
					className='flex lg:text-[52px] text-[36px] text-center'
					style={{ fontFamily: 'EinaBold' }}
				>
					We’d love to be in touch
				</h1>
				<p
					className='text-[16px] text-[#301F1ECC] lg:w-[494px]'
					style={{ fontFamily: 'EinaRegular' }}
				>
					We’d love to hear from you. If you’d prefer to email us instead, reach
					out to
					<span>
						<u> support@buymadeafrika.com</u>
					</span>
				</p>
			</div>
			<div className='lg:flex lg:justify-between'>
				<div className='lg:w-1/2'>
					<div className='flex  px-[20px] items-center justify-between w-full lg:w-[482px] h-[66px] border rounded-[12px] border-[#301F1E24]'>
						<div className='text-[#301F1ECC] '>
							<h1
								className='text-[16px] '
								style={{ fontFamily: 'EinaSemiBold' }}
							>
								Call Us
							</h1>
							<p className='text-[14px]' style={{ fontFamily: 'EinaRegular' }}>
								Mon-Fri from 8am to 4pm
							</p>
						</div>
						<div className='flex items-center w-[112px] h-[34px] border rounded-[8px] border-[#301F1E] text-[12px]'>
							<a
								className='flex  justify-center mx-auto'
								href='https://api.whatsapp.com/message/LAY5MLHPXFXUG1?autoload=1&app_absent=0'
								target='_blank'
								rel='noopener noreferrer'
							>
								<u>Call our team</u>
							</a>
						</div>
					</div>
					<div className=' lg:h-[546px] mt-[97px]'>
						<h1
							className='text-[#EA702C] text-[28px] mb-[39px]'
							style={{ fontFamily: 'EinaBold' }}
						>
							Write Us a Note
						</h1>
						<Form />
					</div>
				</div>
				<div className=' lg:w-[552px] lg:h-[797px] hidden lg:block'>
					<div className='flex justify-end h-full mt-8'>
						<img
							src={ContactUs}
							className=''
							style={{ width: '100%', height: 'auto' }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contact;
