import MarketFrame from '../images/marketFrame.png';
import Globe from '../images/globe.png';
import Vector1 from '../images/vector1.png';
import Vector2 from '../images/vector2.png';
import Place1 from '../images/place1.png';
import Place2 from '../images/place2.png';
import Place3 from '../images/place3.png';
import Place4 from '../images/place4.png';
const MarketOpportunity = () => {
	return (
		<div className='lg:flex justify-between lg:w-[1112px] lg:h-[650px] bg-[#EA702C0A] rounded-[25px] mt-[64px] mb-[24px]'>
			<div className='flex flex-col justify-between mt-[77px] lg:ml-[72px] lg:w-[305px]'>
				<div className='lg:pt-0 pt-[49px] pl-[31px] pr-[22px] lg:pr-0 lg:pl-0'>
					<p
						style={{ fontFamily: 'EinaBold' }}
						className='lg:text-[36px] text-[24px] font-bold'
					>
						Market Opportunity
					</p>
					<p
						style={{ fontFamily: 'EinaO1Regular' }}
						className='lg:text-[16px] text-[14px] font-normal lg:pt-0 pt-[16px]'
					>
						With our innovative solution, we tap into this underserved market,
						providing them with a seamless shopping experience that preserves
						their cultural identity.
					</p>
				</div>

				<img src={MarketFrame} className='lg:pb-[99px] hidden lg:block' />
			</div>

			<div className='lg:w-[633px] lg:mr-[56px] h-[289px] relative w-full mb-[48px] mt-[68px] lg:mt-0 lg:mb-0'>
				<div className='absolute lg:top-[262px]'>
					<img src={Globe} className='' />
				</div>
				<div className='absolute lg:h-[164px] lg:w-[256px] lg:top-[314px] lg:left-[197px]'>
					<img src={Vector1} />
				</div>
				<div className='absolute lg:h-[144px] lg:w-[362px] lg:top-[244px] left-[94px]'>
					<img src={Vector2} />
				</div>
				<div className='absolute h-[70px] w-[70px] lg:top-[293px] lg:left-[421px] top-[90px]'>
					<img src={Place1} />
				</div>
				<div className='absolute h-[51px] w-[51px] lg:top-[345px] left-[67px]'>
					<img src={Place4} />
				</div>
				<div className='absolute h-[61px] w-[61px] lg:top-[365px] left-[288px] top-[90px]'>
					<img src={Place2} />
				</div>
				<div className='absolute h-[38px] w-[38px] lg:top-[449px] left-[199px]'>
					<img src={Place3} />
				</div>
			</div>
		</div>
	);
};

export default MarketOpportunity;
