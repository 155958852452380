import React, { useState } from 'react';
import AboutUs from '../images/aboutUs.png';
import Telescope from '../images/telescope.png';
import Measurement from '../images/ai-measurement.png';

const About = () => {
	const [isMissionShown, setIsMissionShown] = useState(true);
	return (
		<div className='lg:w-full items-center lg:mb-[96px]'>
			<div className='lg:w-[712px] lg:mx-auto justify-center text-center mb-[72px] lg:mb-0'>
				<h1
					className='lg:text-[52px] text-[32px] text-[#301F1E] font-normal mb-[24px] lg:mb-0'
					style={{ fontFamily: 'EinaBold' }}
				>
					Traditional Meets Modern. A Vast Cultural Fashion Market
				</h1>
				<p
					className='text-[13px] lg:text-[16px] text-[#301F1ECC] lg:px-[40px] font-normal'
					style={{ fontFamily: 'EinaRegular' }}
				>
					A bustling African market scene where people are wearing and selling
					sustainable fashion. The vibrant atmosphere, with a mix of traditional
					and modern fashion, would highlight the diversity and cultural
					richness of African fashion.
				</p>
			</div>

			<div className='lg:mt-[96px] lg:w-full lg:mb-[138px] mb-[68px]'>
				<img src={AboutUs} />
			</div>
			<div className='lg:flex bg-[#FFBEC829] lg:w-[1127px] lg:h-[650px]'>
				<div className='lg:w-[490px] lg:mt-[77px] lg:pt-0 pt-[43px] mr-[22px] lg:mr-0 ml-[31px] lg:ml-[44px]'>
					<h1
						className='lg:text-[36px] text-[24px]'
						style={{ fontFamily: 'EinaSemiBold' }}
					>
						Who Are We
					</h1>
					<p
						className='text-[16px] text-[#000000B2] lg:w-[305px] lg:mb-[56px]'
						style={{ fontFamily: 'Eina01Regular' }}
					>
						Our AI system uses two photos to provide accurate measurements for a
						perfect fit.
					</p>
					<img src={Telescope} className='sm:w-[100px] lg:w-full' />
				</div>
				<div className='lg:mr-[46px] pl-[12px] lg:ml-6 bg-white lg:w-[605px] lg:mt-[47px] pt-[32px] lg:pt-0 rounded-t-[27px]'>
					<div className='flex lg:pt-[47px] justify-center gap-2 mx-auto mb-[34px] lg:mb-0'>
						<div
							onClick={() => setIsMissionShown(true)}
							className={`border w-[70px] rounded-full py-2 px-2 cursor-pointer ${
								isMissionShown
									? `border-white bg-[#FFEBE0]`
									: `border-[#FFEBE0]`
							}  `}
						>
							<p className='text-[13px] font-bold'>Vision</p>
						</div>
						<div
							onClick={() => setIsMissionShown(false)}
							className={`border rounded-full py-2 px-2 cursor-pointer ${
								isMissionShown
									? `border-[#FFEBE0]`
									: `border-white bg-[#FFEBE0]`
							}  `}
						>
							<p className='text-[13px] font-bold'>Mission</p>
						</div>
					</div>
					{isMissionShown ? (
						<div className='lg:px-[44px] lg:pt-[44px]'>
							<h1
								className='text-[#EA702C] mb-[36px] text-[24px] lg:text-[28px]'
								style={{ fontFamily: 'EinaSemiBold' }}
							>
								The Vision
							</h1>

							<ul className='list-disc ml-[16px] lg:ml-0'>
								<li className='mb-3'>
									Vision is to become the leading platform for slow fashion in
									the world, and to create a positive impact on the environment,
									society, and economy.
								</li>
								<li className='mb-3'>
									Aims to reduce the carbon footprint of the fashion industry by
									promoting sustainable and ethical practices, such as using
									organic and recycled materials, minimizing waste and
									pollution, and supporting fair trade and labor rights.
								</li>
								<li className='mb-3'>
									Also aims to empower African designers and artisans by
									providing them with access to a global market, increasing
									their income and livelihood, and showcasing their creativity
									and culture.
								</li>
								<li>
									Ultimately aims to create a community of conscious consumers
									and producers who share the same values and vision of slow
									fashion.
								</li>
							</ul>
						</div>
					) : (
						<div className='lg:px-[44px] lg:pt-[44px]'>
							<h1
								className='text-[24px] lg:text-[28px] text-[#EA702C] lg:mb-[36px]'
								style={{ fontFamily: 'EinaSemiBold' }}
							>
								The Mission
							</h1>
							<p className='mt-[16px] lg:mt-0 text-[16px] text-[#000000B2]'>
								Our mission is to bridge the gap between African fashion and the
								diaspora by providing accessible, customizable, and sustainable
								clothing options. Our unique solution combines AI technology,
								customization, and strategic partnerships to cater to the needs
								of African immigrants and expatriates in Europe and North
								America.
							</p>
						</div>
					)}
				</div>
			</div>
			<div className='block lg:hidden'>
				<img src={Measurement} className='mt-[58px] mb-[60px]' />
			</div>
		</div>
	);
};

export default About;
