import React from 'react';
import Featurescard from '../components/FeaturesCard';
import ShopImg from '../images/shop.png';
import Money from '../images/money.png';
import Dashboard from '../images/dashboard1.png';
import Phone from '../images/phone-1.png';
import Order from '../images/track-order.png';
import SewingMachine from '../images/sewingMachine.png';
import VectorPlay from '../images/vectorPlay.png';
import YoutubePlay from '../images/youtubePlay.png';
import { FaGooglePlay } from 'react-icons/fa';
import { FaApple } from 'react-icons/fa6';
import SellMiaFrame from '../images/sellMiaFrame.png';

const SellMIA = () => {
	return (
		<div>
			<div className=''>
				<div className='flex gap-4  justify-between'>
					<div className='flex flex-col lg:w-[539px] lg:mt-[100px] mt-[50px]'>
						<div className='h-[251px] '>
							<div className='flex flex-col'>
								<h1
									className='lg:text-[45px] text-[30px] lg:leading-[61px] justify-center text-center items-center lg:text-left lg:items-start lg:justify-start'
									style={{ fontFamily: 'EinaBold' }}
								>
									Unleash your creativity with SellMIA.
								</h1>
								<p className='lg:text-[16px] text-[14px] text-[#301F1ECC] lg:w-[450px] mt-[24px] justify-center text-center items-center lg:text-left lg:items-start lg:justify-start'>
									Connect with global fashion enthusiasts and showcase your
									unique designs. Together, we will redefine African fashion.
								</p>
							</div>
							<div className='flex lg:gap-6 gap-2 mt-6 lg:w-[290px] lg:items-start lg:justify-start  items-center justify-center '>
								<a href='https://play.google.com/store/apps/details?id=com.madeinafrika.seller&pcampaignid=web_share'>
									<div className='bg-[#EA702C] flex w-[136px] h-[48px]  border rounded-[56px] items-center justify-center text-center'>
										<div className='flex gap-1 text-white text-center items-center justify-center y-auto'>
											<FaGooglePlay />
											<p>Play store</p>
										</div>
									</div>
								</a>

								<a href='https://apps.apple.com/ng/app/sell-mia/id1639711519'>
									<div className='bg-[#EA702C] flex w-[136px] h-[48px]  border rounded-[56px] items-center justify-center text-center'>
										<div className='flex gap-1 text-white text-center items-center justify-center y-auto'>
											<FaApple />
											<p>App store</p>
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
					<div className='lg:h-[668px] lg:w-[499px] hidden lg:block'>
						<img src={Dashboard} className='p-2' />
					</div>
				</div>
			</div>
			<img src={Dashboard} className='p-2 block lg:hidden mt-[60px] lg:mt-0' />
			<div className='mb-[110px]'>
				<div className='lg:flex'>
					<div className='bg-gradient-to-b from-[#FFF5DA] to-[#FFFFFF] h-[409px] w-full rounded-[25px] lg:w-[552px] lg:h-[449px] mb-[40px] lg:mr-[20px]'>
						<Featurescard
							title='Own a Shop'
							subTitle='Own a shop, curate your boutique, connect with a global audience, and enjoy seamless transactions. Your shop, your style, your success – all in one app!'
							imgUrl={ShopImg}
						/>
					</div>
					<div className='bg-gradient-to-b from-[#FFF1F3] to-[#FFFFFF] rounded-[25px] lg:w-[552px] lg:h-[449px] mb-[40px] '>
						<Featurescard
							title='Easy Cash Withdrawal'
							subTitle='Our seller dashboard makes managing your profits a breeze. You can quickly transfer your earnings to your preferred account with just a few clicks.'
							imgUrl={Money}
						/>
					</div>
				</div>
				<div className='lg:flex mt-[40px]'>
					<div className='bg-[#9292920A]  rounded-[25px] lg:w-[552px] h-[449px] mb-[40px] mr-[20px]'>
						<Featurescard
							title='Track your order'
							subTitle='Track your order monitor your purchase in real-time for a stress-free, transparent shopping experience.'
							imgUrl={Order}
						/>
					</div>
					<div className='bg-gradient-to-b from-[#E5F5FF] to-[#FFFFFF] rounded-[25px] lg:w-[552px] lg:h-[449px] mb-[40px] '>
						<Featurescard
							title='Easy Customization'
							subTitle='Easily mix and match styles, colors, and sizes with just a few taps, ensuring a seamless and unique shopping experience.'
							imgUrl={SewingMachine}
						/>
					</div>
				</div>
			</div>
			<div className='lg:h-[553px] mb-[48px] lg:mb-[80px] relative'>
				<img src={YoutubePlay} />
				<img
					src={VectorPlay}
					className='absolute lg:top-[257px] top-[80px] left-[150px] lg:left-[490px] w-[34px] h-[37px]'
				/>
			</div>
			<div className='lg:w-[621px]  flex flex-col mx-auto text-center items-center justify-center lg:mb-[127px] mb-[91px]'>
				<p
					className='text-[28px] tracking-tight text-[#301F1E] leading-[42.5px]'
					style={{ fontFamily: 'EinaBold' }}
				>
					Elevate your design game with SellMIA
				</p>
				<p
					className='text-[#000000B2] text-[14px] lg:w-[500px] justify-center leading-5 pt-4'
					style={{ fontFamily: 'EinaO1Regular' }}
				>
					Display your innovations and connect with a global audience of fashion
					lovers. Join the movement to elevate African fashion today.
				</p>
			</div>
		</div>
	);
};

export default SellMIA;
