import React, { useState } from 'react';
import { FaGooglePlay } from 'react-icons/fa';
import { FaApple } from 'react-icons/fa6';
import Youtube from '../images/youtubeplaceholder.png';
import Group from '../images/Group.png';
import Downloads from '../images/downloads.png';
import Clothes from '../images/clothes.png';
import Fabrics from '../images/fabrics.png';
import Designer from '../images/designers.png';
import Shoes from '../images/shoes.png';
import Accessories from '../images/accessories.png';
import Influencers from '../images/influencers.png';
import Assoebi from '../images/assoebi.png';
import Cloud1 from '../images/cloud1.png';
import Cloud2 from '../images/cloud2.png';
import Crafted1 from '../images/crafted1.png';
import Crafted2 from '../images/crafted2.png';
import { RiStoreLine } from 'react-icons/ri';
import { FaRobot } from 'react-icons/fa6';
import { HiShoppingCart } from 'react-icons/hi';
import SlowFashion from '../images/slowFashion.png';
import Carousel from '../components/Carousel';
import AssoebiMobile from '../images/assoebi2.png';
import AiMobile from '../images/aiMobile.png';
import Craft from '../images/craft.png';
import Measurement from '../images/ai-measurement.png';

const Home = () => {
	return (
		<div className='lg:mt-[50px] w-full lg:flex flex-col'>
			<div className='lg:w-[819px] w-full items-center justify-center mx-auto text-center'>
				<h1
					className='lg:text-[54px] text-[30px] text-center font-bold pt-[52px] lg:pt-0'
					style={{ fontFamily: 'EinaBold' }}
				>
					Climate-friendly company empowering African designers and celebrating
					<span className='text-[#EA702C]'> diverse African fashion.</span>
				</h1>
				<h1
					className='leading-6 mt-[28px] text-[16px] text-center font-normal lg:w-[589px] mx-auto text-[#301F1ECC]'
					style={{ fontFamily: 'EinaRegular' }}
				>
					Unlock the full potential of the BuyMIA Apps that leverages AI
					technology to provide a seamless digital shopping experience
				</h1>
				<div className='mt-6 lg:w-[290px] flex gap-6 items-center justify-center text-center mx-auto'>
					<a href='https://play.google.com/store/apps/details?id=com.madeinafrika.madeinafrica&pcampaignid=web_share'>
						<div className='flex w-[136px] h-[48px] bg-[#EA702C] border rounded-[56px] items-center justify-center text-center'>
							<div className='flex gap-1 text-white text-center items-center justify-center y-auto'>
								<FaGooglePlay />
								<p>Play store</p>
							</div>
						</div>
					</a>

					<a href='https://apps.apple.com/ng/app/buy-made-in-afrika/id1640453099'>
						<div className='flex w-[136px] h-[48px] bg-[#EA702C] border rounded-[56px] items-center justify-center text-center'>
							<div className='flex gap-1 text-white text-center items-center justify-center y-auto'>
								<FaApple />
								<p>App store</p>
							</div>
						</div>
					</a>
				</div>
				<div className='mt-[87px]'>
					<img src={Youtube} />
				</div>
				<div className='mt-[58px] lg:w-[480px] mx-auto'>
					<p
						style={{ fontFamily: 'EinaBold' }}
						className='lg:text-[36px] text-[24px]'
					>
						Innovation Meet Traditions
					</p>
					<p
						className='text-base text-[#000000B2]'
						style={{ fontFamily: 'EinaO1Regular' }}
					>
						Our solution leverages AI, customization, and strategic partnerships
						to serve the fashion needs of Africans and enthusiasts worldwide.
					</p>
				</div>
			</div>
			<div className=''>
				<Carousel />
			</div>

			<div className='mt-[58px] lg:w-[509px] mx-auto text-center'>
				<p
					style={{ fontFamily: 'EinaBold' }}
					className='text-[28px] lg:text-[36px]'
				>
					We Bring Africa to You
				</p>
				<p
					className='text-[#000000B2] text-[14px] lg:text-[16px]'
					style={{ fontFamily: 'EinaO1Regular' }}
				>
					Shop with confidence, knowing each item is ethically sourced and
					crafted with care. Experience the diversity, creativity, and soul of
					African fashion – delivered straight to your door. We bring Africa to
					you.
				</p>
			</div>
			<div className='lg:flex my-[36px] gap-[22px] lg:h-[409px]'>
				<div>
					<img src={Downloads} className='lg:w-[553px]' />
				</div>
				<div className='flex gap-[22px] mt-[21px] lg:mt-0'>
					<img src={Clothes} className='lg:w-[270px] w-[180px]' />
					<img src={Fabrics} className='lg:w-[270px] w-[180px]' />
				</div>
			</div>
			<div className='lg:flex my-[36px] gap-[22px] lg:h-[409px] hidden lg:block'>
				<img src={Designer} className='lg:w-1/4' />
				<img src={Shoes} className='lg:w-1/4' />
				<img src={Accessories} className='lg:w-1/4' />
				<img src={Influencers} className='lg:w-1/4' />
			</div>
			<div className='flex gap-[22px] mt-[21px] lg:mt-0 block lg:hidden'>
				<img src={Designer} className=' w-[180px]' />
				<img src={Shoes} className=' w-[180px]' />
			</div>

			<div className='flex gap-[22px] mt-[21px] lg:mt-0 block lg:hidden'>
				<img src={Accessories} className=' w-[180px]' />
				<img src={Influencers} className=' w-[180px]' />
			</div>

			<div className='mt-[64px] lg:mt-0 lg:flex justify-between bg-[#322929] lg:h-[361px] lg:w-[1126px] rounded-[29px] mb-[66px]'>
				<div className='lg:pt-[57px] lg:pl-[72px] pt-[40px] pl-[22px]'>
					<h1
						className='text-[24px] lg:text-[36px] text-white lg:w-[250px]'
						style={{ fontFamily: 'EinaBold' }}
					>
						Discover Asoebi & More
					</h1>
					<p
						className='lg:w-[387px] text-[#FFFFFFB2] text-[16px]'
						style={{ fontFamily: 'EinaO1Regular' }}
					>
						Our mission is to empower individuals to discover their style
						through a seamless, AI-driven shopping experience, while celebrating
						African craftsmanship in clothing, jewelry, shoes, and bags.
					</p>
					<div className='mt-6 lg:w-[290px] flex lg:gap-6 gap-2 mb-[55px] lg:mb-0 '>
						<a href='https://play.google.com/store/apps/details?id=com.madeinafrika.madeinafrica&pcampaignid=web_share'>
							<div className='flex lg:w-[136px] w-[107px] h-[38px] lg:h-[48px]  border rounded-[56px] items-center justify-center text-center'>
								<div className='text-[12px] lg:text-[13px] flex gap-1 text-white text-center items-center justify-center y-auto'>
									<FaGooglePlay />
									<p>Play store</p>
								</div>
							</div>
						</a>

						<a href='https://apps.apple.com/ng/app/buy-made-in-afrika/id1640453099'>
							<div className='text-[12px] lg:text-[13px] w-[107px] flex lg:w-[136px] h-[38px] lg:h-[48px]  border rounded-[56px] items-center justify-center text-center'>
								<div className='flex gap-1 text-white text-center items-center justify-center y-auto'>
									<FaApple />
									<p>App store</p>
								</div>
							</div>
						</a>
					</div>
				</div>
				<div>
					<img src={Assoebi} className='hidden lg:block lg:w-[660px]' />
					<img src={AssoebiMobile} className='lg:hidden block' />
				</div>
			</div>
			<div className='lg:flex'>
				<div className='flex flex-col lg:w-[552px] lg:h-[700px]'>
					<div className='pt-[30px] lg:pl-[56px] ml-[31px] mr-[22px] lg:pr-[48px]'>
						<h1 className='text-[28px]' style={{ fontFamily: 'EinaBold' }}>
							AI Measurement
						</h1>
						<p
							className='lg:text-[16px] text-[14px] text-[#322929B2] pt-2 lg:w-[420px]'
							style={{ fontFamily: 'EinaO1Regular' }}
						>
							Our AI system generates accurate measurements from just two
							pictures, simplifying the process and ensuring a customized fit.
							This technology eliminates measurement barriers, offering a
							seamless shopping experience..
						</p>
					</div>
					<div>
						<img src={AiMobile} className='mt-[60px] block lg:hidden' />
					</div>
					<div className='relative hidden lg:block w-full'>
						<div className='absolute top-[70px] right-[100px]'>
							<img src={Group} className=' lg:w-[377px] h-[401px]' />
						</div>
						<div className='absolute top-[350px] left-[20px]'>
							<img src={Cloud1} className=' lg:w-[150px] h-[72px]' />
						</div>
						<div className='absolute top-[130px] left-0 inset-0  z-[-1]'>
							<img src={Cloud2} className=' lg:w-[250px]' />
						</div>
					</div>
				</div>
				<div className='lg:flex flex-col lg:w-[552px] lg:h-[700px] bg-gradient-to-b from-[#FFF5DA] to-[#FFE6C8] to-[#FFFFFF] rounded-[42px]'>
					<div className='mt-[20px] lg:mt-0 pt-[30px] lg:pl-[56px] lg:pr-[48px] ml-[31px] mr-[22px] lg:mx-0'>
						<h1
							className='lg:text-[28px] text-[24px]'
							style={{ fontFamily: 'EinaBold' }}
						>
							Uniquely crafted for you
						</h1>
						<p
							className='text-[16px] text-[#322929B2] pt-2 lg:w-[420px]'
							style={{ fontFamily: 'EinaO1Regular' }}
						>
							Each piece is meticulously tailored for a perfect fit. African
							customization, honed over centuries, showcases exceptional
							expertise.
						</p>
					</div>
					<div className='flex justify-between hidden pt-[70px] w-full'>
						<div>
							<img src={Crafted2} className='lg:w-[300px] w-[200px]' />
						</div>
						<div>
							<img src={Crafted1} className='lg:w-[181px] w-[200px]' />
						</div>
					</div>
					<div className='flex  justify-between pt-[70px]'>
						<img src={Craft} className='' />
					</div>
				</div>
			</div>
			<div className='lg:flex lg:h-[582px] bg-[#FFF5F3] rounded-[45px] mt-24 mb-[80px]'>
				<div className='lg:pt-[75px] lg:pl-[56px] lg:pr-[48px] pl-[31px] pt-[52px] pr-[21px]'>
					<h1
						className='lg:text-[28px] text-[24px] font-bold'
						style={{ fontFamily: 'EinaBold' }}
					>
						We Promote Slow Fashion
					</h1>
					<p
						className='lg:text-[16px] text-[14px] text-[#322929B2] pt-2 lg:w-[420px]'
						style={{ fontFamily: 'EinaO1Regular' }}
					>
						Africa has a large pool of talented and creative designers and
						artisans but they lack access to global markets and customers
					</p>
					<div
						className='flex gap-[12px] items-center lg:text-[16px] text-[12px] text-[#322929B2] mt-[40px] lg:w-[420px]'
						style={{ fontFamily: 'EinaO1Regular' }}
					>
						<div>
							<RiStoreLine className='text-[#EA702C] h-[24px] w-[24px]' />
						</div>
						<p className='lg:w-[316px]'>
							Marketplace app that connects consumers with African fashion
							makers
						</p>
					</div>
					<div
						className='flex gap-[12px] items-center text-[12px] lg:text-[16px] text-[#322929B2] mt-[20px] lg:w-[420px]'
						style={{ fontFamily: 'EinaO1Regular' }}
					>
						<div>
							<FaRobot className='text-[#EA702C] h-[24px] w-[24px]' />
						</div>
						<p className=''>
							Uses a seamless AI technology that capture your accurate
							measurement just by taking 2 pictures of yourself.
						</p>
					</div>
					<div
						className='flex gap-[12px] items-center lg:text-[16px] text-[12px] text-[#322929B2] mt-[20px] lg:w-[420px]'
						style={{ fontFamily: 'EinaO1Regular' }}
					>
						<div className='flex text-[#EA702C] border-2 border-[#EA702C] items-center justify-center rounded-full'>
							<HiShoppingCart className='justify-center h-[12px] w-[10px] m-1' />
						</div>
						<p className=''>
							Consumer can browse through a curated sections of products, choose
							their fabrics, colours and designs and place orders directly with
							the makers.
						</p>
					</div>
					<div className='lg:mt-6 mt-[40px] lg:w-[290px] flex lg:gap-6 gap-2 text-[#EA702C]'>
						<a href='https://play.google.com/store/apps/details?id=com.madeinafrika.madeinafrica&pcampaignid=web_share'>
							<div className='flex w-[107px] h-[38px] lg:w-[136px] lg:h-[48px]  border rounded-[56px] border-[#EA702C] items-center justify-center text-center'>
								<div className='flex gap-1  text-center items-center justify-center y-auto'>
									<FaGooglePlay />
									<p>Play store</p>
								</div>
							</div>
						</a>

						<a href='https://apps.apple.com/ng/app/buy-made-in-afrika/id1640453099'>
							<div className='flex w-[107px] h-[38px] lg:w-[136px] lg:h-[48px]  border rounded-[56px] border-[#EA702C] items-center justify-center text-center'>
								<div className='flex gap-1  text-center items-center justify-center y-auto'>
									<FaApple />
									<p>App store</p>
								</div>
							</div>
						</a>
					</div>
				</div>
				<div className='hidden lg:block flex pt-[70px]'>
					<img src={SlowFashion} />
				</div>
				<div className='block lg:hidden'>
					<img src={Measurement} className='mt-[58px] mb-[60px]' />
				</div>
			</div>
		</div>
	);
};

export default Home;
