const Featurescard = ({ title, subTitle, imgUrl }) => {
	return (
		<div className='flex h-full flex-col justify-between mt-[40px]  lg:mx-[72px] ml-[26px] mr-[17px]'>
			<div className='mb-[40px] lg:mb-0'>
				<h1
					className='lg:text-[34px] text-[24px] text-[#301F1E] pb-[24px] pt-[40px] lg:pt-0'
					style={{ fontFamily: 'EinaO1Semibold' }}
				>
					{title}
				</h1>
				<p
					className='lg:text-[16px] text-[14px] text-[#000000B2]'
					style={{ fontFamily: 'EinaO1Regular' }}
				>
					{subTitle}
				</p>
			</div>

			<div className='mb-[40px]'>
				<img src={imgUrl} />
			</div>
		</div>
	);
};

export default Featurescard;
