import react from 'react';
import FooterLogo from '../images/footer-logo.png';
import { FaXTwitter } from 'react-icons/fa6';
import { RiFacebookFill } from 'react-icons/ri';
import { FaInstagram } from 'react-icons/fa6';
import { CiYoutube } from 'react-icons/ci';
import { MdAlternateEmail } from 'react-icons/md';
import { FaLocationArrow } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const Footer = () => {
	return (
		<div className='lg:w-full sm:h-[832px] lg:h-[463px] md:h-[463px] bg-[#1C1313] text-white'>
			<div className='lg:mx-[156px] lg:my-[145px] lg:flex md:flex m-[24px] md:justify-between lg:justify-between pb-[98px] lg:pb-0'>
				<div className='lg:mb-[20px] mb-[80px] mt-[95px] lg:mt-0'>
					<NavLink to='/' className=''>
						<img src={FooterLogo} className='w-[136px]' />
					</NavLink>

					<div className='flex gap-x-0.5 mt-6'>
						<div className='w-[43px] h-[43px] border b-1 border-[#313131] rounded-lg flex items-center'>
							<FaXTwitter className='mx-auto' style={{ fontSize: '30px' }} />
						</div>
						<div className='w-[43px] h-[43px] border b-1 border-[#313131] rounded-lg flex items-center'>
							<RiFacebookFill
								className='mx-auto'
								style={{ fontSize: '30px' }}
							/>
						</div>
						<div className='w-[43px] h-[43px] border b-1 border-[#313131] rounded-lg flex items-center'>
							<FaInstagram className='mx-auto' style={{ fontSize: '30px' }} />
						</div>
						<div className='w-[43px] h-[43px] border b-1 border-[#313131] rounded-lg flex items-center'>
							<CiYoutube className='mx-auto' style={{ fontSize: '30px' }} />
						</div>
					</div>
					<div className='w-[197px] text-[13px] mt-6'>
						<p>Copyright © 2024 Buy Made In Afrika - All Rights Reserved.</p>
					</div>
				</div>
				<div className='text-white/[0.7] hidden lg:block'>
					<p className='mb-[53px] text-[#A8A8A8]'>Product</p>
					<NavLink to='sellmia'>
						<p className='mb-3'>SellMIA</p>
					</NavLink>
					<NavLink to='/'>
						<p className='mb-3'>BuyMIA</p>
					</NavLink>
					<NavLink to='/'>
						<p>Terms of services</p>
					</NavLink>
				</div>
				<div className='text-white/[0.7] hidden lg:block'>
					<p className='mb-[53px] text-[#A8A8A8]'>Company</p>
					<NavLink to='/contact'>
						<p className='mb-3'>Contact us</p>
					</NavLink>
					<NavLink to='/about'>
						<p className='mb-3'>About us</p>
					</NavLink>

					<p>Partner with us</p>
				</div>

				<div className='lg:hidden flex justify-between lg:gap-8'>
					<div className='text-white/[0.7]'>
						<p className='mb-[53px] text-[#A8A8A8]'>Product</p>
						<NavLink to='sellmia'>
							<p className='mb-3'>SellMIA</p>
						</NavLink>
						<NavLink to='/'>
							<p className='mb-3'>BuyMIA</p>
						</NavLink>
						<NavLink to='/'>
							<p>Terms of services</p>
						</NavLink>
					</div>
					<div className='text-white/[0.7]'>
						<p className='mb-[53px] text-[#A8A8A8]'>Company</p>
						<NavLink to='/contact'>
							<p className='mb-3'>Contact us</p>
						</NavLink>
						<NavLink to='/about'>
							<p className='mb-3'>About us</p>
						</NavLink>

						<p>Partner with us</p>
					</div>
				</div>

				<div className='text-white/[0.7] mt-[117px] lg:mt-0'>
					<p className='mb-[20px] text-[#A8A8A8]'>Reach us via</p>
					<div className='flex gap-x-2 items-center'>
						<MdAlternateEmail />
						<p className='mb-3 pt-3'>Support@madeinafrika.com</p>
					</div>
					<div className='flex gap-x-2 items-center'>
						<FaLocationArrow />
						<p className='mb-3 pt-3'>United States</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
