import Influencing from '../images/influencing.png';
import InfluncerFrame from '../images/influencerFrame.png';

const Influencer = () => {
	return (
		<div className='lg:flex justify-between lg:w-[1112px] lg:h-[650px] bg-[#EA702C0A] rounded-[25px] mt-[64px] mb-[24px]'>
			<div className='flex flex-col justify-between mt-[77px] lg:ml-[72px] lg:w-[305px]'>
				<div className='lg:pt-0 pt-[49px] pl-[31px] pr-[22px] lg:pr-0 lg:pl-0'>
					<p
						style={{ fontFamily: 'EinaBold' }}
						className='lg:text-[36px] text-[24px] font-bold'
					>
						Influencer Collaboration
					</p>
					<p
						style={{ fontFamily: 'EinaO1Regular' }}
						className='lg:text-[16px] text-[14px] font-normal lg:pt-0 pt-[16px]'
					>
						We collaborate with content creators to showcase our products, and
						they earn a percentage of sales generated through their promotions.
					</p>
				</div>

				<img src={InfluncerFrame} className='pb-[99px] hidden lg:block' />
			</div>

			<div className='lg:w-[746px] lg:mr-[56px] mb-[48px]'>
				<img src={Influencing} className='' />
			</div>
		</div>
	);
};

export default Influencer;
